<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4" order-sm="1">
				<v-subheader>Nueva categoría</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevaCategoria
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="categoria"
        />
      </v-col>
      <v-col cols="12" md="8" order-sm="1">
        <v-subheader>Listado categorías</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="categorias.filter(f => !loading)"
          :headers="categoriasHeader"
          class="fixed-checkbox"
          item-key="idCategoria"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="categorias"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item.n="{ item, index }">
            <td style="white-space: nowrap !important;padding-left: 22px !important;">{{
            index+1 }}</td>
          </template>
          <template v-slot:item.acciones="{ item }">
            <div class="d-flex">
              <v-btn
                @click="categoria = item"
                small
                rounded
                color="secondary"
              >
                <v-icon left>mdi-pencil</v-icon>
                Editar
              </v-btn>
              <v-btn
                @click="deleteCategoria(item)"
                small
                class="ml-1"
                rounded
                color="error"
              >
                <v-icon left>mdi-delete</v-icon>
                Eliminar
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
		
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate, perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    categorias: Array,
  },
  components: {
    NuevaCategoria: () => import("./NuevaCategoria.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      categoria: null,
      inlineFilters: {},
      keyHelper: 0,
      categoriasHeader: [
        { text: "Nº", value: "n", sortable: false, filterable: false },
        { text: "Nombre", value: "nombre", cellClass: "pa-1 text-no-wrap font-weight-bold" },
        { text: "Acciones", value: "acciones", sortable: false, filterable: false },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
    };
  },
  watch: {
    categoria() {
      this.keyHelper++;
    },
  },
  methods: {
    parseDate,
    async deleteCategoria({ idCategoria, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Categoría: ${nombre}`,
        `ATENCIÓN: Todos las recetas asociadas a esta categoría dejarán de estar asociados a una categoría (excepto las que tengan más de una). ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar la categoría?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/categorias/" + idCategoria,
      });
      this.$emit('reload')
    },
  },
};
</script>
